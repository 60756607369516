<template>
    <div>
        <LoadingOverlay :loading="loading" />
        <div class="daily-reward-box rewardPhoto">
            <div class="pk_rb-boxes pk_rb-boxes-lastWeek">
                <div class="pk_rb-box pk_rb-leftBox" v-if="topPkLastWeekData.length > 0">
                    <img :src='topPkLastWeekData[0].user.profile_images[0].image_name' class="pk_rb-userImage" alt="" />
                    <img src="../../assets/images/pk-rank1.png" class="pk-rank_img pk-rank1_img" alt="" />
                    <div class="pk_rb-userDetails" @click="redirectProfile(topPkLastWeekData[0].user_id, topPkLastWeekData[0].user.profile_id, topPkLastWeekData[0].user.charm_level)">
                        <div class="pk_rb-nameLvBox">
                            <span class="pk_rb-userName">{{ topPkLastWeekData[0].user.name }}</span>
                            <span class="pk_rb-lvImage">
                                <img :src='topPkLastWeekData[0].user.last_charm_level_icon' alt="" />
                                <span class="pk_rb-lvValue">{{ topPkLastWeekData[0].user.charm_level }}</span>
                            </span>
                        </div>
                        <div class="pk_rb-beansBox">
                            <span class="pk_rb-beansVal">{{ topPkLastWeekData[0].total_coin_earned }}</span>
                            <span class="pk_rb-beansImage"><img :src='beansIcon' alt="" /></span>
                        </div>
                        <div v-if="topPkLastWeekData[0].eligible < '1' " class="mt-eligibleBtn user_eligibleCondi">Not Eligible </div>
                    </div>
                    <div class="pk_rb_rank1-innerUsers">
                        <ul class="pk_rb-innerUserList"
                            v-if="topPkLastWeekData[0].top3sender && topPkLastWeekData[0].top3sender.length > 0">
                            <li class="top3SenderLeft_box pk_rb-innerUserBoxes" v-for="(sender, index) in topPkLastWeekData[0].top3sender"
                                :key="index" @click="redirectProfile(sender.sender_data.id, sender.sender_data.profile_id, sender.sender_data.charm_level)">
                                <div class="pk_rb-innerUser-leftBox">
                                    <div class="pk_rb-innerUser-name"> {{ sender.sender_data.name }} </div>
                                    <div class="pk_rb-innerUser-beansVal">
                                        <span class="pk_rb-beansVal">{{ sender.total_gift_price }}</span>
                                        <span class="pk_rb-beansImage"><img :src='beansIcon' alt="" /></span>
                                    </div>
                                </div>
                                <div class="pk_rb-innerUser-rightBox">
                                    <div class="pk_rb-innerUser-imgBox">
                                        <img :src="sender.sender_data.profile_images && sender.sender_data.profile_images.length > 0 ? sender.sender_data.profile_images[0].image_name : defaultUserImage"
                                            alt="Sender Image" />
                                    </div>
                                    <img :src="require(`@/assets/images/pk_circle-rank${index + 1}.svg`)"
                                        class="pk_rb-innerUser-rankImg" alt="" />
                                </div>
                            </li>
                        </ul>                        
                    </div>
                    <div class="pk_rb_rewardBox">Reward {{ topPkLastWeekData[0].reward_coin }}</div>
                </div>
                <div class="pk_rb-box pk_rb-rightBox" v-if="topPkLastWeekData.length > 1">
                    <img :src='topPkLastWeekData[1].user.profile_images[0].image_name' class="pk_rb-userImage" alt="" />
                    <img src="../../assets/images/pk-rank2.png" class="pk-rank_img pk-rank2_img" alt="" />
                    <div class="pk_rb-userDetails" @click="redirectProfile(topPkLastWeekData[1].user_id, topPkLastWeekData[1].user.profile_id, topPkLastWeekData[1].user.charm_level)">
                        <div class="pk_rb-nameLvBox">
                            <span class="pk_rb-userName">{{ topPkLastWeekData[1].user.name }}</span>
                            <span class="pk_rb-lvImage">
                                <img :src='topPkLastWeekData[1].user.last_charm_level_icon' alt="" />
                                <span class="pk_rb-lvValue">{{ topPkLastWeekData[1].user.charm_level }}</span>
                            </span>
                        </div>
                        <div class="pk_rb-beansBox">
                            <span class="pk_rb-beansVal">{{ topPkLastWeekData[1].total_coin_earned }}</span>
                            <span class="pk_rb-beansImage"><img :src='beansIcon' alt="" /></span>
                        </div>
                        <div v-if="topPkLastWeekData[1].eligible < '1' " class="mt-eligibleBtn user2_eligibleBox">
                            <div class="user_eligibleCondi">Not Eligible </div>
                        </div>
                    </div>
                    <div class="pk_rb_rank1-innerUsers pk_rb_rank2-innerUsers">
                        <ul class="pk_rb-innerUserList" v-if="topPkLastWeekData[1].top3sender && topPkLastWeekData[1].top3sender.length > 0">
                            <li class="top3SenderRight_box pk_rb-innerUserBoxes" v-for="(sender, index) in topPkLastWeekData[1].top3sender"
                            :key="index" @click="redirectProfile(sender.sender_data.id, sender.sender_data.profile_id, sender.sender_data.charm_level)">
                                <div class="pk_rb-innerUser-rightBox">
                                    <div class="pk_rb-innerUser-imgBox">
                                        <img :src="sender.sender_data.profile_images && sender.sender_data.profile_images.length > 0 ? sender.sender_data.profile_images[0].image_name : defaultUserImage"
                                            alt="Sender Image" />
                                    </div>
                                    <img :src="require(`@/assets/images/pk_circle-rank${index + 1}.svg`)"
                                        class="pk_rb-innerUser-rankImg" alt="" />
                                </div>
                                <div class="pk_rb-innerUser-leftBox">
                                    <div class="pk_rb-innerUser-name">{{ sender.sender_data.name }}</div>
                                    <div class="pk_rb-innerUser-beansVal">
                                        <span class="pk_rb-beansVal">{{ sender.total_gift_price }}</span>
                                        <span class="pk_rb-beansImage"><img :src='beansIcon' alt="" /></span>
                                    </div>
                                </div>
                            </li>
                            <!-- <li class="pk_rb-innerUserBoxes">
                                <div class="pk_rb-innerUser-rightBox">
                                    <div class="pk_rb-innerUser-imgBox">
                                        <img src="../../assets/images/pk-user1.png" alt="" />
                                    </div>
                                    <img src="../../assets/images/pk_circle-rank2.svg" class="pk_rb-innerUser-rankImg"
                                        alt="" />
                                </div>
                                <div class="pk_rb-innerUser-leftBox">
                                    <div class="pk_rb-innerUser-name">RIO KUMAR</div>
                                    <div class="pk_rb-innerUser-beansVal">
                                        <span class="pk_rb-beansVal">15896532</span>
                                        <span class="pk_rb-beansImage"><img :src='beansIcon' alt="" /></span>
                                    </div>
                                </div>
                            </li>
                            <li class="pk_rb-innerUserBoxes">
                                <div class="pk_rb-innerUser-rightBox">
                                    <div class="pk_rb-innerUser-imgBox">
                                        <img src="../../assets/images/pk-user1.png" alt="" />
                                    </div>
                                    <img src="../../assets/images/pk_circle-rank3.svg" class="pk_rb-innerUser-rankImg"
                                        alt="" />
                                </div>
                                <div class="pk_rb-innerUser-leftBox">
                                    <div class="pk_rb-innerUser-name">RIO KUMAR</div>
                                    <div class="pk_rb-innerUser-beansVal">
                                        <span class="pk_rb-beansVal">15896532</span>
                                        <span class="pk_rb-beansImage"><img :src='beansIcon' alt="" /></span>
                                    </div>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                    <div class="pk_rb_rewardBox">Reward {{ topPkLastWeekData[1].reward_coin }}</div>
                </div>
                <div class="pk-logoImage-box">
                    <img src="../../assets/images/pk-logo.png" class="pk-logoImage" alt="" />
                </div>
            </div>           
        </div>
        <div class="listingPattern">
            <div class="fixHeight">
                <div class="listBoxes" v-for="(reward, index) in topPkLastWeekData.slice(2)" :key="reward.id">
                    <div class="leftBxb" @click="redirectProfile(reward.user_id, reward.user.profile_id, reward.user.charm_level)">
                        <div class="s-no">{{ index + 3 }}</div>
                        <div class="listIconBox">
                            <div class="profPic">
                                <img :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name : defaultUserImage'
                                    class="user" alt="User Image" />
                            </div>
                            <img class="frames" :src='reward.user.last_pic_frame' alt="" />
                        </div>
                        <div>
                            <!-- <span class="rewards top-0" v-if="index < 18">Reward <span class="rewards-font">{{ reward.reward_coin }}</span></span> -->
                            <span class="rewards top-0" v-if="reward.reward_coin>0">Reward <span class="rewards-font">{{ reward.reward_coin }}</span></span>
                            <div :class="{ 'marqueeContainer': reward.user.name.length > 8 }">
                                <div v-if="reward.user.name.length > 8" class="marquee">
                                    <div class="marquee-content">{{ reward.user.name }}</div>
                                </div>
                                <div v-else>{{ reward.user.name }}</div>
                            </div>
                            <div class="eligibleBox-wrap">
                                <div class="base">
                                    <img :src='reward.user.last_charm_level_icon' alt="" />
                                    <b>{{ reward.user.charm_level }}</b>
                                </div>
                                <div v-if="reward.eligible < '1' " class="user_eligibleCondi">Not Eligible </div>
                            </div>
                        </div>
                    </div>
                    <div class="rightBxb">
                        <span>
                            <img :src='beansIcon' alt="Beans Icon" />{{ reward.total_coin_earned }}                                                       
                        </span>
                        <ul class="top3Sender-userList pk_innerUser-imgList" v-if="reward.top3sender.length > 0">
                            <li v-for="(sender, index) in reward.top3sender" :key="index"
                            @click="redirectProfile(sender.sender_data.id, sender.sender_data.profile_id, sender.sender_data.charm_level)">
                                <div class="pk_innerUser-imgBox">
                                    <img :src="sender.sender_data.profile_images && sender.sender_data.profile_images.length > 0 && sender.sender_data.profile_images[0].image_name ? sender.sender_data.profile_images[0].image_name : defaultUserImage"
                                    @error="(event) => event.target.src = defaultUserImage" alt="" />
                                </div>
                                <img :src="require(`@/assets/images/pk_circle-rank${index + 1}.svg`)"
                                    class="pk_innerUser-rankImg" alt="Rank Image" />
                            </li>
                        </ul>
                        <!-- <ul class="pk_innerUser-imgList">
                            <li>
                                <div class="pk_innerUser-imgBox">
                                    <img src="../../assets/images/pk-user1.png" alt="" />
                                </div>
                                <img src="../../assets/images/pk_circle-rank1.svg" class="pk_innerUser-rankImg" alt="" />
                            </li>
                            <li>
                                <div class="pk_innerUser-imgBox">
                                    <img src="../../assets/images/pk-user2.png" alt="" />
                                </div>
                                <img src="../../assets/images/pk_circle-rank2.svg" class="pk_innerUser-rankImg" alt="" />
                            </li>
                            <li>
                                <div class="pk_innerUser-imgBox">
                                    <img src="../../assets/images/pk-user1.png" alt="" />
                                </div>
                                <img src="../../assets/images/pk_circle-rank3.svg" class="pk_innerUser-rankImg" alt="" />
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import pkPodiumImage from '../../assets/images/pk-podium-image.svg'
import beansIcon from '../../assets/images/beans-icon.png'
import LoadingOverlay from '../LoadingOverlay.vue'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
export default {
    name: 'TopPkLastWeekTabCont',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            topPkLastWeekData: [],           
            pkPodiumImage,            
            beansIcon,
            defaultUserImage: defaultUserImage,
            loading: false,
        }
    },
    mounted() {
        this.fetchLastWeekData();

        this.restartLeftAnimation();
        setInterval(this.restartLeftAnimation, 12000); // Adjust this interval as needed
        this.restartRightAnimation();
        setInterval(this.restartRightAnimation, 12000);
    },
    methods: {
        fetchLastWeekData() {
            this.loading = true;
            axios.get('https://zeep.live/api/top-rpk-weekly?type=last_week').then(response => {
                this.topPkLastWeekData = response.data.result.data;
                this.checkAllImagesExist();
            })
                .catch(error => {
                    console.error("Error Featching This Week Data", error)
                })
                .finally(() =>{
                    this.loading = false;
                })
        },

        checkAllImagesExist() {
            this.topPkLastWeekData.forEach(reward => {
                // Check user profile images
                if (reward.user.profile_images && reward.user.profile_images.length > 0) {
                    const imageUrl = reward.user.profile_images[0].image_name;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (exists) {
                                reward.user.profile_images[0].image_name = imageUrl;  // Keep the actual image if it exists
                            } else {
                                reward.user.profile_images[0].image_name = this.defaultUserImage;  // Use default image if it doesn't exist
                                console.warn('User image does not exist, replaced with default.');
                            }
                        });
                } else {
                    reward.user.profile_images = [{ image_name: this.defaultUserImage }];
                    console.warn('User image not found, using default.');
                }

                // Handle top3sender images
                if (reward.top3sender && reward.top3sender.length > 0) {
                    reward.top3sender.forEach((sender, index) => {
                        if (sender.sender_data.profile_images && sender.sender_data.profile_images.length > 0) {
                            const senderImageUrl = sender.sender_data.profile_images[0].image_name;
                            this.checkImageExists(senderImageUrl)
                                .then(exists => {
                                    if (exists) {
                                        console.log(`Sender image at index ${index} exists.`);
                                        sender.sender_data.profile_images[0].image_name = senderImageUrl;  // Keep the actual image if it exists
                                    } else {
                                        sender.sender_data.profile_images[0].image_name = this.defaultUserImage;  // Use default image if it doesn't exist
                                        console.warn(`Sender image at index ${index} does not exist, replaced with default.`);
                                    }
                                });
                        } else {
                            sender.profile_images = [{ image_name: this.defaultUserImage }];
                            console.warn(`Sender image not found for sender at index ${index}, using default.`);
                        }
                    });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    resolve(true);  // Image exists
                };
                img.onerror = function () {
                    resolve(false);  // Image does not exist or cannot load
                };
                img.src = imageUrl;
            });
        },

        restartLeftAnimation() {
            const boxes = this.$el.querySelectorAll('.top3SenderLeft_box');
            boxes.forEach((box, index) => {
                box.style.animation = 'none';
                box.offsetHeight;
                box.style.animation = `slide-in 2s forwards, slide-out 2s 7s forwards`;
                box.style.animationDelay = `${index}s, 10s`;
            });
        },

        restartRightAnimation() {
            const boxes2 = this.$el.querySelectorAll('.top3SenderRight_box');
            boxes2.forEach((box2, index) => {
                box2.style.animation = 'none';
                box2.offsetHeight;
                box2.style.animation = `slide-in-2 2s forwards, slide-out-2 2s 7s forwards`;
                box2.style.animationDelay = `${index}s, 10s`;
            });
        },

        redirectProfile(id, profile_id, level) {
            // if (window.navigator.userAgent.indexOf("Android") > -1) {
                // const baseURL = 'female-weekly-rewards';
                const baseURL = '#';
                const params = {
                    id: id,
                    profile_id: profile_id,
                    level: level
                };

                const queryString = Object.keys(params)
                    .map(key => `${key}=${params[key]}`)
                    .join('&');
                const url = `${baseURL}?${queryString}`;
                  window.location.href = url;
                // Android.redirectProfile(url);
            // }
        }
    }
}    
</script>