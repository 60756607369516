<template>
    <div class="IH-container">
        <div class="IH-banner" @click="redirectProfile(IconicHostData.profile_images[0].user_id, IconicHostData.profile_id)">
            <img :src='IHBanner' alt="" />
            <img :src='ParticalesBG' class="articales-BGImage" alt="" />
            <div class="hostImage-wrap">
                <img :src='CrownImage' class="crownImage" alt="" />
                <div class="ImageFrame-box">                    
                    <img :src='ImageFrame' class="IH-imageFrame" alt="ImageFrame" />
                    <div class="hostImage-box">
                        <div class="hostImage-boxInner">
                            <!-- <img :src="blackImage" alt="User Image" /> -->
                            <img :src="IconicHostData.profile_images?.[0]?.image_name || defaultUserImage"
                                alt="User Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hostName-box">
                <img :src='HostNameTagBG' alt="hostName-bg" />
                <div class="hostName">{{ IconicHostData.name }}</div>
            </div>
            <div class="IH-rewardBeand">
                <img :src='WinAmountImage' alt="" />
            </div>
            <div class="IH-winMessageBox">
                <img :src='LinesBar' alt="" />
                <div class="IH-winMessage">Congratulations to our latest winner of<br /> the Iconic Host Challenge!
                </div>
                <img :src='LinesBar' alt="" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import blackImage from '../../assets/images/theIconicHost/blackImage.png'
import IHBanner from '../../assets/images/theIconicHost/background-banner.png'
import ParticalesBG from '../../assets/images/theIconicHost/particales-image.png'
import CrownImage from '../../assets/images/theIconicHost/crownImage.png'
import ImageFrame from '../../assets/images/theIconicHost/host-image-frame.png'
import HostNameTagBG from '../../assets/images/theIconicHost/name-tag-bg.png'
import WinAmountImage from '../../assets/images/theIconicHost/win-amountImage.png'
import LinesBar from '../../assets/images/theIconicHost/lines-bar.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import '../../assets/css/iconicHost.css'
export default {
    data() {
        return {
            IconicHostData: {
                profile_images: [],
                name: ''
            },
            blackImage: blackImage,
            IHBanner: IHBanner,
            ParticalesBG: ParticalesBG,
            ImageFrame: ImageFrame,
            CrownImage: CrownImage,
            HostNameTagBG: HostNameTagBG,
            WinAmountImage: WinAmountImage,
            LinesBar: LinesBar,
            defaultUserImage: defaultUserImage
        }
    },
    mounted() {
        this.fetchIconicHost();
    },
    methods: {
        fetchIconicHost() {
            const apiUrl = `https://zeep.live/api/the-iconic-host-challenge-reward`;
            axios.get(apiUrl)
                .then(response => {
                    this.IconicHostData = response.data.result;
                    console.warn(apiUrl)
                    console.warn("Data", this.IconicHostData)
                })
                .catch(error => {
                    console.error('Error fetching IconicHostData  :', error);
                })
        },
        checkAllImagesExist() {
            this.IconicHostData.forEach(reward => {
                if (reward.profile_images && reward.profile_images.length > 0) {
                    const imageUrl = reward.profile_images[0].image_name;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.profile_images[0].image_name = this.defaultUserImage;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },

        redirectProfile(id, profile_id, level) {
            // if (window.navigator.userAgent.indexOf("Android") > -1) {
                // const baseURL = 'female-weekly-rewards';
                const baseURL = '#';
                const params = {
                    id: id,
                    profile_id: profile_id
                };

                const queryString = Object.keys(params)
                    .map(key => `${key}=${params[key]}`)
                    .join('&');
                const url = `${baseURL}?${queryString}`;
                  window.location.href = url;
                // Android.redirectProfile(url);
            // }
        }

    }
}
</script>