<template>
    <div class="historyDetails-wrapper flipCoinHistoryDetails luckySeven_wrapper">
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="historyDetails-topHeadBox">
            <div class="back-histort" @click="goBack">
                <div class="angle purple-leftAngle"></div>
            </div>            
            <div class="historyDetails-topHD">
                <h2 class="pnik-HD">Details</h2>
            </div>
        </div>
        <div class="historyDetails-header" v-if="topHeaderDetails">
            <div class="historyDetails-headerLeftBox">
                <div class="">{{ this.contestId }}</div>
                <!-- <div class="flipCoin-detailsResultBox">
                    <span>Result:</span>                    
                    <b>{{ topHeaderDetails.status }}</b>
                </div> -->
            </div>
            <div class="historyDetails-headerRightBox">
                <div class="">
                    {{ topHeaderDetails.total_winner }}
                    <img :src='userIcon' class="userIcon" alt="" />{{ topHeaderDetails.total_won }}
                    <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                </div>
                <div>
                    Tip :{{ topHeaderDetails.total_tips }} 
                    <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                </div>
            </div>
        </div>
        <div class="historyTable-wrapper flipCoin-historyTable">
            <div class="table-responsive" ref="tableContainer">
                <table class="table">
                    <thead style="vertical-align: top">
                        <tr>
                            <th style="width: 3.75rem">Rank</th>
                            <th style="width: 6.25rem">Winners <div v-if="topHeaderDetails">{{ topHeaderDetails.total_winner }}</div></th>
                            <th style="width: 3.75rem">Total Bet</th>
                            <th style="width: 3.75rem">Bet On <div v-if="topHeaderDetails">{{ topHeaderDetails.winning }}</div></th>
                            <th style="width: 3.75rem">Prizes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="thimbleGameHistoryBetsDetails.length === 0">
                            <tr>
                                <td colspan="5" class="text-center">No data available</td>
                            </tr>
                        </template>
                        <template v-else v-for="(betsDetails, index) in thimbleGameHistoryBetsDetails">
                            <tr v-if="betsDetails.status == 2 || betsDetails.status == 3" :key="betsDetails.contestid">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    <div class="box-mw-90 user-picName-box">
                                        <div class="userPic">
                                            <img :src='(betsDetails.image.length > 0) ? betsDetails.image : defaultProfile'
                                                class="user" alt="User Image" />
                                        </div>
                                        <div class="userName">{{ betsDetails.name }} </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span class="totalBet">
                                            {{ betsDetails.total_bet }}
                                            <span class="diamond_size-10 diamond-iconBox">
                                                <img :src='diamondIcon' alt="" />
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span class="totalBet">
                                            {{ betsDetails.winning }}
                                            <span class="diamond_size-10 diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span class="totalBet">
                                            {{ betsDetails.total_won }}
                                            <span class="diamond_size-10 diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        
                    </tbody>
                </table>
                <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import diamondIcon from '../../assets/images/diamond.png'
import defaultProfile from '../../assets/images/default_profile.jpeg';
import userIcon from '../../assets/images/user-icon.png'
import LoadingOverlay from '../LoadingOverlay.vue'
import { RouterLink } from 'vue-router'
export default {
    name: 'ThimbleGameHistoryDetails',
    props: {
        contest_id: String,
        user_id: String,
    },
    components: {
        LoadingOverlay,
        RouterLink
    },
    data() {
        return {
            thimbleGameHistoryBetsDetails: [],
            diamondIcon: diamondIcon,
            defaultProfile: defaultProfile,
            userIcon: userIcon,
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1,
            contestId: '',
            userId: '',
        }
    },
    mounted() {
        this.contestId = this.$route.params.contest_id;
        this.userId = this.$route.params.user_id;
        this.thimbleGameFetchBetHistoryDetails();
        this.setupScrollListener();
    },
    methods: {
        thimbleGameFetchBetHistoryDetails() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `https://zeep.live/api/thimble-game/betdetailapi.php?page=${this.currentPage}&contest_id=${this.contestId}&user_id=${this.userId}`;
            axios.get(apiUrl)
                .then(response => {
                    this.thimbleGameHistoryBetsDetails = [...this.thimbleGameHistoryBetsDetails, ...response.data.data];
                    this.topHeaderDetails = response.data
                    this.winningNo = response.data
                    this.totalPages = response.data.total_pages;
                    this.checkAllImagesExist();

                    // console.warn(apiUrl)
                    // console.warn(this.topHeaderDetails)
                })
                .catch(error => {
                    console.error('Error fetching car game history details data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })                
        },
        checkAllImagesExist() {
            this.thimbleGameHistoryBetsDetails.forEach(reward => {
                if (reward.image.length > 0) {
                    const imageUrl = reward.image;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.image = this.defaultProfile;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if (scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.thimbleGameFetchBetHistoryDetails();
            }
        },
        goBack() {
            this.$router.go(-1); // Goes back to the previous page in the browser history
        }
    }
}
</script>