<template>
    <div class="historyDetails-wrapper junglemines-wrappers">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>Rule</h2>
            </div>
        </div>
        <div class="ruleInfo-wrapper">
            <div class="ruleContent">
                <ol>
                    <li>The user selects the bet amount.</li>
                    <li>The user places a bet.</li>
                    <li>The user chooses a mine to open.</li>
                    <li>Each mine has a different multiplier (X).</li>
                    <li>The bomb can be anywhere between 1 and 5, which means the user will have 10 to 14 chances of
                        winning, and the bomb’s range will change\ randomly in every game.</li>
                    <li>The user can choose up to 14 mines, but if they select a mine with a bomb, the game will be
                        over, and they will lose all their winnings.</li>
                    <li>The user can choose to cash out their winnings at any time before selecting the 14th mine or
                        hitting a bomb.</li>
                </ol>
                <div style="font-weight: 600">Total mines 15 = 1-5 Bombs, 10 - 14 have coins</div>
                <div class="jm_totalreward-box">
                    <p><b>TOTAL REWARD 5X OF BET AMOUNT</b></p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Mines</th>
                                <th>Multiple</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in multipliersListData" :key="index">
                                <td>{{ item.no_of_moves }}</td>
                                <td>{{ item.multiplier }}X</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'JungleMinesRules',
    data() {
        return {
            multipliersListData: [],
        }
    },
    mounted() {
        this.multipliersList();
    },
    methods: {
        multipliersList() {
            const apiUrl = 'https://zeep.live/api/mines-game-multipliers';
            axios.get(apiUrl).then(response => {
                if (response.data.success && response.data.result && response.data.result.data) {
                    this.multipliersListData = [
                        ...this.multipliersListData,
                        ...response.data.result.data,
                    ];
                    // console.log("Data fetched:", this.multipliersListData);
                } else {
                    console.error("Invalid response structure or no data found");
                }
            })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        },
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        }
    }
}
</script>