<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner">
      <div class="loading-circle"></div>
      <div class="loading-circle"></div>
      <div class="loading-circle"></div>
      <!-- <img :src='heartLoader' alt="" /> -->
    </div>
  </div>
</template>

<script>
// import heartLoader from '../assets/images/loader.gif'
// import heartLoader from '../assets/images/loader_2.gif'
export default {
  name: 'LoadingOverlay',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  // data() {
  //   return {
  //     heartLoader: heartLoader,
  //   }
  // }
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-spinner {
  width: 95px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  background: #ddd;
  left: 15%;
  transform-origin: 50%;
  animation: loading-circle .5s alternate infinite ease;
}

@keyframes loading-circle {
  0% {
    top: 40px;
    height: 12px;
    border-radius: 50%;
    transform: scaleX(1);
    opacity: 0.5;
  }

  40% {
    height: 12px;
    border-radius: 50%;
    transform: scaleX(1);
    opacity: 0.3;
  }

  100% {
    top: 0%;
    opacity: 1;
  }
}

.loading-circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.loading-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.popupLoading {
  background-color: transparent !important;
}
.popupLoading .loading-circle {
  background-color: #ccc !important;
}

.groupBattle-wrapper .popupLoading {
        background-color: #000000bd !important;
}

</style>